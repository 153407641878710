import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const YellowBlueOrange = () => (
  <StaticImage
    src='../../../images/framedDisplay/runOne/yellow-blue-orange-rothko-web-sample-run-1.png'
    alt='Dandy Lines Boogie-Woogie: Vincent Van-Gogh, Sunflowers'
  />
);

export default YellowBlueOrange;
