import * as React from 'react';

import colors from '../../colors/colors';

import faqs from '../../data/faqs';
import links from '../../data/links';

import FAQ from './FAQ';

const FAQSection = ({ anchor }) => (
  <div
    style={{
      margin: '0 auto',
      padding: '0 2rem',
      maxWidth: '40rem',
      padding: '0 1rem',
    }}
  >
    <h2 id={anchor} className='Anchor CenterTextOnWide'>
      FAQs
    </h2>
    <p className='SecondaryText CenterTextOnWide'>
      Please reach out to the team on our{' '}
      <a href={links.discord} style={{ color: colors.red }}>
        Discord server
      </a>{' '}
      for questions not covered below.
    </p>
    {faqs.map(({ question, answer, id }) => (
      <FAQ key={`faq-list-${id}`} question={question} answer={answer} />
    ))}
  </div>
);

export default FAQSection;
