import * as React from 'react';
import { Link } from 'gatsby';

import TwoColumns from '../display/TwoColumns';

import CollectionItemDetails from './CollectionItemDetails';

import './collection-item.css';

const CollectionItem = ({ Image, details, seriesPage, invert = false, piece }) => (
  <>
    <TwoColumns
      align='center'
      justify='center'
      style={{
        backgroundColor: 'white',
      }}
    >
      <div className={`CollectionItem ${invert ? 'CollectionItem-Image-Inverted' : 'CollectionItem-Image'}`}>
        <Link to={seriesPage}>
          <div>{Image}</div>
        </Link>
      </div>
      <div className={`CollectionItem ${invert ? 'CollectionItem-Details-Inverted' : 'CollectionItem-Details'}`}>
        <CollectionItemDetails details={details} seriesPage={seriesPage} />
      </div>
    </TwoColumns>
  </>
);

export default CollectionItem;
