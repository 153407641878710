import * as React from 'react';

import NuBleuII from '../../components/imageWrappers/runOne/NuBleuII';
import NuBleuIIScreengrab from '../../components/imageWrappers/samples/NuBleuII';

const nuBleuII = {
  seriesName: 'Run 1 Matisse Series',
  name: 'Nu Bleu II',
  artistName: 'Henri Matisse',
  artistBio: '1869, France - 1954, France',
  date: 1952,
  medium: 'Gouache, cut and glued on paper mounted on canvas',
  measurements: '103,8 x 86 cm',
  classification: 'Collage',
  creditLine: `© Succession H. Matisse`,
  // rights: `© Succession H. Matisse`,
  link: 'https://www.centrepompidou.fr/en/ressources/oeuvre/CtQ1tcy',
  location: 'Centre Pompidou',
};

export default {
  Image: <NuBleuII />,
  ReferenceImage: <NuBleuIIScreengrab />,
  details: nuBleuII,
  seriesPage: '/series/run-1-matisse',
};
