import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const MuralForTheTerracePlazaHotelScreengrab = () => (
  <StaticImage
    src='../../../images/samples/run-1/mural-for-the-terrace-plaza-hotel-miro-sample.jpg'
    alt={`Isolated sample of Joan Miró's Mural for the Terrace Plaza Hotel, Cincinnati`}
    placeholder='dominantColor'
  />
);

export default MuralForTheTerracePlazaHotelScreengrab;
