import * as React from 'react';

import roadmap from '../../data/roadmap';
import RoadmapItem from './RoadmapItem';

const RoadmapSection = ({ anchor }) => (
  <div
    style={{
      margin: 'calc(2rem + 2vw) auto',
      padding: '0 2rem',
      maxWidth: '40rem',
      padding: '0 1rem',
    }}
  >
    <h2 id={anchor} className='Anchor CenterTextOnWide'>
      Roadmap
    </h2>
    {roadmap.map(({ title, description }) => (
      <RoadmapItem key={`roadmap-item-${title}`} title={title} description={description} />
    ))}
  </div>
);

export default RoadmapSection;
