import * as React from 'react';

import MuralForTheTerracePlazaHotelImage from '../../components/imageWrappers/runOne/MuralForTheTerracePlazaHotel';
import MuralForTheTerracePlazaHotelScreengrab from '../../components/imageWrappers/samples/MuralForTheTerracePlazaHotel';

const muralForTheTerracePlazaHotel = {
  seriesName: 'Run 1 Miró Series',
  name: 'Mural for the Terrace Plaza Hotel, Cincinnati',
  artistName: 'Joan Miró',
  artistBio: 'Spanish, b.1893, d.1983',
  date: 1947,
  medium: 'Oil on Canvas',
  measurements: '259.1 x 935.4 cm',
  classification: 'Painting',
  creditLine: `Gift of Thomas Emery's Sons, Inc.`,
  rights: `© Successió Miró / Artists Rights Society (ARS), New York / ADAGP, Paris 2016`,
  link: 'https://www.cincinnatiartmuseum.org/art/explore-the-collection?id=23171413',
  location: 'Cincinnati Art Museum',
  screengrab: '',
};

export default {
  Image: <MuralForTheTerracePlazaHotelImage />,
  ReferenceImage: <MuralForTheTerracePlazaHotelScreengrab />,
  details: muralForTheTerracePlazaHotel,
  seriesPage: '/series/run-1-miro',
};
