import * as React from 'react';
import { Link } from 'gatsby';

import BoogieWoogieLogo from '../imageWrappers/BoogieWoogieLogo';

import FlexWrapper from '../display/FlexWrapper';

import colors from '../../colors/colors';

import './banner.css';

const MainBanner = ({ anchor }) => (
  <div id={anchor}>
    <FlexWrapper
      align='center'
      justify='center'
      style={{
        backgroundColor: colors.red,
        padding: 'calc(2rem + 5vw)',
      }}
    >
      <BoogieWoogieLogo />
    </FlexWrapper>
    <div
      className='BannerContent'
      style={{
        backgroundColor: colors.white,
      }}
    >
      <div className='BannerColumnWrapper'>
        <h1>Dandy Lines Boogie-Woogie</h1>
        <p className='BannerColumn'>
          We first select a work of art as inspiration. We then isolate features of the selected work&mdash;such as
          color and color density. We use{' '}
          <span
            className='CodeBlock'
            style={{
              padding: '0.25rem',
              backgroundColor: colors.offWhite,
              margin: '0 0.125rem',
              borderRadius: '3px',
            }}
          >
            code
          </span>{' '}
          to randomly generate unique abstract representations based on the selected features, iterating until we reach
          the desired aesthetic outcome.
        </p>
        <p className='BannerColumn'>
          By keeping a human element in the generative process for each Boogie-Woogie, we ensure the resulting
          Boogie-Woogie NFT is representative of the original. Each Boogie-Woogie NFT must also establish its own unique
          aesthetic that is visually engaging.
        </p>
        <p className='BannerColumn'>
          We are releasing a run of 125 Boogie-Woogie NFTs each quarter, beginning November 2021 and ending Q4 of 2024. For
          each run, we select five (5) works of art and print a series (25 unique 1-of-1 Boogie-Woogie NFTs) per work of
          art. This, along with a reserve of 345 NFTs for <Link to='/#Bespoke'>Bespoke Boogie-Woogie's</Link>, totals
          only 1970 Boogie-Woogie NFTs in this first generation.
        </p>
        <p className='BannerTagline'>
          <b>What will you see in your Boogie-Woogie?</b>
        </p>
      </div>
    </div>
    <FlexWrapper align='stretch' justify='between' style={{ backgroundColor: colors.yellowSaturated, height: '3rem' }}>
      <div style={{ width: '70%' }}></div>
      <div style={{ width: '10%', backgroundColor: colors.yellowDesaturated }}></div>
      <div style={{ width: '20%', backgroundColor: colors.yellowSaturated }}></div>
    </FlexWrapper>
  </div>
);

export default MainBanner;
