import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const YellowBlueOrangeScreengrab = () => (
  <StaticImage
    src='../../../images/samples/run-1/yellow-blue-orange-rothko-sample.jpg'
    alt={`Isolated sample of Mark Rothko's Yellow and Blue`}
    placeholder='dominantColor'
  />
);

export default YellowBlueOrangeScreengrab;
