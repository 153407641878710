import * as React from 'react';

import colors from '../../colors/colors';

const RoadmapItem = ({ title, description }) => {
  return (
    <div style={{ margin: '0 0 1.5rem' }}>
      <h3 style={{ margin: '0 0 0.5rem' }}>{title}</h3>
      <div
        style={{
          padding: '0 1rem',
          borderLeft: `5px solid ${colors.yellowDesaturated}`,
        }}
      >
        {description}
      </div>
    </div>
  );
};

export default RoadmapItem;
