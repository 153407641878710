import * as React from 'react';

const listParagraphStyle = {
  fontSize: '0.8rem',
  margin: 0,
  lineHeight: '1.7rem',
};

export default [
  {
    title: 'November 2021 - Run 1 Drop - COMING SOON! ',
    description: (
      <div>
        <p style={listParagraphStyle}>New York City - Piet Mondrian</p>
        <p style={listParagraphStyle}>Sunflowers - Vincent van Gogh</p>
        <p style={listParagraphStyle}>Nu Bleu II - Henri Matisse</p>
        <p style={listParagraphStyle}>Yellow and Blue (Yellow, Blue on Orange) - Mark Rothko</p>
        <p style={listParagraphStyle}>Mural for the Terrace Plaza Hotel, Cincinnati - Joan Miró</p>
      </div>
    ),
  },
  {
    title: 'Q1 2022 - Run 2 Drop',
    description: (
      <div>
        <p style={listParagraphStyle}>Le Déjeuner sur l'herbe - Édouard Manet</p>
        <p style={listParagraphStyle}>Un dimanche après-midi à l'Île de la Grande Jatte - Georges Seurat</p>
        <p style={listParagraphStyle}>The Persistence of Memory - Salvador Dalí</p>
        <p style={listParagraphStyle}>Woman with a Parasol – Madame Monet and Her Son - Claude Monet</p>
        <p style={listParagraphStyle}>Les Demoiselles d'Avignon - Pablo Picasso</p>
      </div>
    ),
  },
  {
    title: 'Q2 2022 - Run 3 Drop',
    description: (
      <div>
        <p style={listParagraphStyle}>Portrait de Gertrude Stein - Pablo Picasso</p>
        <p style={listParagraphStyle}>Composition 8 (Komposition 8) - Vasily Kandinsky</p>
        <p style={listParagraphStyle}>Un bar aux Folies Bergère - Édouard Manet</p>
        <p style={listParagraphStyle}>Der Kuss - Gustav Klimt</p>
        <p style={listParagraphStyle}>Mona Lisa - Leonardo da Vinci</p>
      </div>
    ),
  },
  {
    title: 'Q3 2022 - Run 4 Drop',
    description: (
      <div>
        <p style={listParagraphStyle}>Water Lilies - Claude Monet</p>
        <p style={listParagraphStyle}>Starry Night - Vincent van Gogh</p>
        <p style={listParagraphStyle}>Simultaneous Windows (2nd Motif, 1st Part) - Robert Delaunay</p>
        <p style={listParagraphStyle}>Gray Tree - Piet Mondrian</p>
        <p style={listParagraphStyle}>No. 10 - Mark Rothko</p>
      </div>
    ),
  },
  {
    title: 'Q4 2022 - Run 5 Drop',
    description: (
      <div>
        <p style={listParagraphStyle}>Nu descendant un escalier n° 2 - Marcel Duchamp</p>
        <p style={listParagraphStyle}>Guernica - Pablo Picasso</p>
        <p style={listParagraphStyle}>Victory Boogie-Woogie - Piet Mondrian</p>
        <p style={listParagraphStyle}>Number 1A, 1948 - Jackson Pollock</p>
        <p style={listParagraphStyle}>Dutch Interior (I) - Joan Miró</p>
      </div>
    ),
  },
  {
    title: '2023 - 2024 - Runs 6 to 13',
    description: (
      <div>
        <p>
          <b>Details Coming Soon</b>
        </p>
      </div>
    ),
  },
  {
    title: 'Full Image Size Download App & Gallery',
    description: (
      <div>
        <p style={listParagraphStyle}>
        We are building an app to allow you to download the original 36Kx36K resolution JPEG of your Boogie-Woogie NFT 
        so you can order larger prints of the Boogie-Woogie to display around your living spaces. 
        If you hold the Boogie-Woogie NFT in your wallet and come to the app we will supply a download link of the largest size we have in our archives. 
        4kx4k resolution JPEGs of each NFT will be stored on Arweave (IPFS) and will serve as the NFT image.
        </p>
        <p style={listParagraphStyle}>
          We are working to build a virtual space where Boogie-Woogie NFTs are the ticket to our Dandy Lines'
          virtual art gallery. This will be a place to see - side by side - your Boogie-Woogie NFT and the original art that inspired the
          Boogie-Woogie NFT.
        </p>
        <p style={listParagraphStyle}>More to come beyond this.</p>
      </div>
    ),
  },
];
