import * as React from 'react';
import { Link } from 'gatsby';

import colors from '../../colors/colors';
import ArrowRightCircle from '../icons/ArrowRightCircle';

import FlexWrapper from '../display/FlexWrapper';

import './collection-item-details.css';

const CollectionItemDetails = ({ details, seriesPage }) => (
  <FlexWrapper
    align='start'
    justify='start'
    column
    style={{
      width: '100%',
    }}
  >
    <div className='CollectionItemDetails'>
      <div className='CollectionItemDetailsHeader'>
        <p className='CollectionItemDetailsArtistName'>{details.seriesName}</p>
        <p className='SeriesInspiration'>Series Inspiration:</p>
        <p
          className='SeriesInspiration'
          style={{ fontWeight: 900 }}
        >{`${details.name} by ${details.artistName}, ${details.date}`}</p>
      </div>
      <div className='CollectionItemDetailsBlock'>
        <div>
          <p>Date:</p>
          <p>Q4 of 2021</p>
        </div>
        <div>
          <p>Prints:</p>
          <p>25</p>
        </div>
      </div>
    </div>
    <Link
      to={seriesPage}
      className='CollectionItemDetailsSeriesLink'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '1rem auto',
        padding: '0.75rem 1rem',
        backgroundColor: colors.red,
      }}
    >
      <span
        style={{
          color: colors.white,
          fontFamily: `'Staatliches', 'Roboto', sans-serif`,
          fontSize: '0.9rem',
          letterSpacing: '0.05rem',
        }}
      >
        View the Series
      </span>
      <ArrowRightCircle aspect='1.5rem' color={colors.yellowDesaturated} />
    </Link>
    <a
      href={`${details.link}`}
      className='CollectionItemDetailsInspirationLink'
    >{`View the Inspiration: ${details.location}`}</a>
  </FlexWrapper>
);

export default CollectionItemDetails;
