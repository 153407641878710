import * as React from 'react';

import YellowBlueOrangeImage from '../../components/imageWrappers/runOne/YellowBlueOrange';
import YellowBlueOrangeScreengrab from '../../components/imageWrappers/samples/YellowBlueOrange';

const yellowBlueOrangeDetails = {
  seriesName: 'Run 1 Rothko Series',
  name: 'Yellow and Blue (Yellow, Blue on Orange)',
  artistName: 'Mark Rothko',
  artistBio: 'American 1903 – 1970',
  date: 1955,
  medium: 'Oil on Canvas',
  measurements: '259.72 × 169.39 × 3.49 cm',
  classification: 'Painting',
  creditLine: `Fellows Fund, Women’s Committee Acquisition Fund, and Patrons Art Fund`,
  rights: `© Kate Rothko Prizel and Christopher Rothko/Artists Rights Society (ARS), New York`,
  link: `https://collection.cmoa.org/objects/90fcbcf3-fa32-44c1-873f-438639922349`,
  location: `Carnegie Museum of Art`,
};

export default {
  Image: <YellowBlueOrangeImage />,
  ReferenceImage: <YellowBlueOrangeScreengrab />,
  details: yellowBlueOrangeDetails,
  seriesPage: '/series/run-1-rothko',
};
