import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NewYorkCity = () => (
  <StaticImage
    src='../../../images/framedDisplay/runOne/new-york-city-mondrian-web-sample-run-1.png'
    alt='Dandy Lines Boogie-Woogie: Piet Mondrian, New York City'
  />
);

export default NewYorkCity;
