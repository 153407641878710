import * as React from 'react';

import colors from '../colors/colors';
import whatIsABespokeBoogieWoogie from './bespokeBoogieWoogie';

const runSpanSpaceStyle = {
  display: 'inline-block',
  width: '8.5rem',
};

export default [
  {
    id: 1,
    question: 'What is Dandy Lines Boogie-Woogie?',
    answer: (
      <div>
        <p>
          Dandy Lines Boogie-Woogie is the first NFT collection created by{' '}
          <a href='https://dandylines.io'>Dandy Lines</a>. In this project we take carefully selected works of art as
          inspiration to produce unique abstract representations of an image through color, scale and random
          manipulation by code. By keeping a human element in the process with color selection and scale of the
          Boogie-Woogie, we can help ensure the random output of the Boogie-Woogie NFT carries over from the original
          inspiration.
        </p>
        <p>
          Motivated by the neoplasticists of the early 20th Century, primarily Mondrian, we look to distill the essence
          of an image through careful color and scale selection while allowing the code to add a randomized element to
          the art.
        </p>
        <p>
          Each calendar quarter starting Q4 2021 through Q4 2024 we will release a run of 125 Boogie-Woogie NFTs based
          on five (5) works of art. That is 25 unique 1-of-1 Boogie-Woogie NFTs per art piece, per run. Total maximum
          NFTs to be created for this 1st generation is 1970 Boogie-Woogie NFTs. That includes the 345 NFTs
          reserved for Bespoke Boogie-Woogie.
        </p>
        <p
          style={{
            fontWeight: 900,
            color: colors.blue,
          }}
        >
          What will you see in your Boogie-Woogie!?
        </p>
      </div>
    ),
  },
  {
    id: 2,
    question: 'Where can I buy a Boogie-Woogie NFT?',
    answer: (
      <>
        <div>
          <p>
            We will make available the option to purchase a Dandy Lines Boogie-Woogie NFT on our website via Dutch auction.
          </p>
        </div>
        <div>
          <p>
            <a href='https://opensea.io/'>OpenSea</a> is the world's first &amp; largest NFT marketplace.
          </p>
          {/*
            // TODO: OpenSea Integration
            <p>
              Visit the <a href=''>Dandy Lines Boogie-Woogie</a> marketplace on Open Sea.
            </p>
          */}
        </div>
      </>
    ),
  },
  {
    id: 3,
    question: 'What is a Bespoke Boogie-Woogie?',
    answer: whatIsABespokeBoogieWoogie,
  },
  {
    id: 4,
    question: 'What is an NFT?',
    answer: (
      <div>
        <p>
          NFT stands for non-fungible token. Non-fungible means not interchangeable, so the value is not a set price but
          varies depending on how much someone or the market deems it is worth. The token part represents how it is
          stored and sold through a blockchain which is like a digital ledger.
        </p>
        <p>
          NFTs are a type of digital asset designed to show that someone has ownership of a unique virtual item, such as
          online pictures and videos.
        </p>
        <p>Dandy Lines Boogie-Woogie NFTs are all ERC-721 compliant, each having a 4kx4k JPEG image stored permanently on Arweave (IPFS).</p>
      </div>
    ),
  },
  {
    id: 5,
    question: `How To Know It's an Original Dandy Lines Boogie-Woogie NFT?`,
    answer: (
      <p>
        The Boogie-Woogie would say: <b>Created by DandyLines</b>
      </p>
    ),
  },
  {
    id: 6,
    question: 'How many NFTs are minted in each run?',
    answer: (
      <div>
        <p>
          Runs will be quarterly through end of 2024. Each run contains only one hundred and twenty five (125)
          Boogie-Woogie NFTs based on five (5) selected works of art. That is 25 unique 1-of-1 Boogie-Woogie NFTs
          created per inspiration piece, per run. No inspiration artists will have more than one inspiration piece per
          run.
        </p>
        <p>
          Maximum Boogie-Woogie NFTs to ever be minted is hard capped at <strong>1970</strong> as defined in our Dandy
          Lines Boogie-Woogie <a href=''>contract</a>.
        </p>
        <p>The mint breakdown is as follows:</p>
        <ul>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 1 - Q4 2021:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 2 - Q1 2022:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 3 - Q2 2022:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 4 - Q3 2022:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 5 - Q4 2022:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 6 - Q1 2023:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 7 - Q2 2023:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 8 - Q3 2023:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 9 - Q4 2023:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 10 - Q1 2024:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 11 - Q2 2024:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 12 - Q3 2024:</b>
            </span>{' '}
            QTY. 125
          </li>
          <li>
            <span style={runSpanSpaceStyle}>
              <b>Run 13 - Q4 2024:</b>
            </span>{' '}
            QTY. 125
          </li>
        </ul>
        <p>
          <b>345 reserved for Bespoke Boogie-Woogie NFTs</b>
        </p>
      </div>
    ),
  },
  {
    id: 7,
    question: 'How do I get an NFT wallet?',
    answer: (
      <div>
        <p>
          The first step in your NFT journey is to create a digital wallet where you'll securely store the crypto
          currency that is used to buy, sell, and create NFTs. This wallet allows you to safely sign in and create
          accounts on NFT marketplaces using web3 technologies.
        </p>
        <p>Metamask seems to be a popular choice.</p>
      </div>
    ),
  },
  {
    id: 8,
    question: 'What is Metamask? ',
    answer: (
      <div>
        <p>
          Metamask is a crypto wallet that can store your Ethereum, and is needed to purchase a Dandy Lines
          Boogie-Woogie.{' '}
        </p>
        <p>Having a wallet gives you an Ethereum address (i.e. 0xABCD….1234), this is where your NFT will be stored.</p>
        <p>
          Learn more about Metamask and how easy it is to use <a href='https://metamask.io/'>here!</a>
        </p>
      </div>
    ),
  },
  {
    id: 9,
    question: 'Will there be a future utility built for my Boogie-Woogie NFT?',
    answer: (
      <div>
        <p>
          Yes, we plan to build a virtual space where Boogie-Woogie NFTs are the ticket to our Dandy Line’s virtual art
          gallery. This ticket will allow entry to see world class art and view your Boogie-Woogie NFT in the metaverse.
        </p>
        <p>More details to come.</p>
      </div>
    ),
  },
  {
    id: 10,
    question: 'What drives the Boogie-Woogie process?',
    answer: (
      <div>
        <p>
          There exists beauty in randomness, though it can become overwhelming and sometimes visually disagreeable. We
          look to balance randomness and control through code to achieve two things:
        </p>
        <ul>
          <li>Allow the essence of what we find inspiring in a piece of art to come through</li>
          <li>Create a unique piece of generative art that is visually engaging and representative of the original</li>
        </ul>
        <p>
          Motivated by the artwork of the neoplasticists from the early 20th Century&mdash;most notably,
          Mondrian&mdash;we find the practice of distilling an object or idea to its core visual essence both
          challenging and rewarding. To explore this area, we look to our artistic capabilities and ability to code.
          Balancing these two to explore this concept of distillation is what drives the core of Boogie-Woogie.
        </p>
        <p>In short, we love code and we love art. Wherever these two intersect, we’ll dig in.</p>
      </div>
    ),
  },
];
