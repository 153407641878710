import * as React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/layouts/core';

import Banner from '../components/banners/MainBanner';
import BespokeBoogieWoogie from '../components/BespokeBoogieWoogie';
import CollectionItem from '../components/collectionItem/CollectionItem';
import FAQSection from '../components/faq/FAQSection';
import RoadmapSection from '../components/roadmap/RoadmapSection';

import muralForTheTerracePlazaHotel from '../data/CollectionItems/muralForTheTerracePlazaHotel';
import newYorkCity from '../data/CollectionItems/newYorkCity';
import nuBleuII from '../data/CollectionItems/nuBleuII';
import sunflowers from '../data/CollectionItems/sunflowers';
import yellowBlueOrange from '../data/CollectionItems/yellowBlueOrange';

import muralForTheTerracePlazaHotelCarouselPiece from '../data/CarouselPieces/muralForTheTerracePlazaHotelCarouselPiece';
import newYorkCityCarouselPiece from '../data/CarouselPieces/newYorkCityCarouselPiece';
import nuBleuIICarouselPiece from '../data/CarouselPieces/nuBleuIICarouselPiece';
import sunflowersCarouselPiece from '../data/CarouselPieces/sunflowersCarouselPiece';
import yellowBlueOrangeCarouselPiece from '../data/CarouselPieces/yellowBlueOrangeCarouselPiece';

import './main.css';

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
We select works of art as inspiration to produce unique abstract representations 
using generative code. Controlling color selection and scale ensures the 
random output of the Boogie-Woogie NFT is unique and captures the essence of the original.`;

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <title>Dandy Lines Boogie-Woogie</title>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@BoogieWoogieNFT' />
        <meta name='twitter:creator' content='@ics_jim' />
        <meta property='og:url' content='https://boogie-woogie.io/' />
        <meta property='og:title' content='Dandy Lines Boogie-Woogie' />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
      <Layout>
        <Banner anchor='Banner' />
        {
          // <div>Check us out on Open Sea!</div>
        }
        <div style={{ margin: 'calc(2rem + 4vw) auto' }}>
          <div className='CenterTextOnWide' style={{ margin: '0 auto', padding: '0 2rem', maxWidth: '40rem' }}>
            <h2 id='Series' className='Anchor'>
              The First Run
            </h2>
            <p className='SecondaryText'>Fourth Quarter, 2021</p>
          </div>
          <CollectionItem
            Image={newYorkCity.Image}
            details={newYorkCity.details}
            seriesPage={newYorkCity.seriesPage}
            piece={muralForTheTerracePlazaHotelCarouselPiece}
          />
          <CollectionItem
            Image={nuBleuII.Image}
            details={nuBleuII.details}
            seriesPage={nuBleuII.seriesPage}
            invert
            piece={newYorkCityCarouselPiece}
          />
          <CollectionItem
            Image={muralForTheTerracePlazaHotel.Image}
            details={muralForTheTerracePlazaHotel.details}
            seriesPage={muralForTheTerracePlazaHotel.seriesPage}
            piece={nuBleuIICarouselPiece}
          />
          <CollectionItem
            Image={sunflowers.Image}
            details={sunflowers.details}
            seriesPage={sunflowers.seriesPage}
            invert
            piece={sunflowersCarouselPiece}
          />
          <CollectionItem
            Image={yellowBlueOrange.Image}
            details={yellowBlueOrange.details}
            seriesPage={yellowBlueOrange.seriesPage}
            piece={yellowBlueOrangeCarouselPiece}
          />
        </div>
        <FAQSection anchor='FAQs' />
        <RoadmapSection anchor='Roadmap' />
        <BespokeBoogieWoogie anchor='Bespoke' />
      </Layout>
    </>
  );
};

export default IndexPage;
