import * as React from 'react';

import colors from '../../colors/colors';

import FlexWrapper from '../display/FlexWrapper';

import ChevronDown from '../icons/ChevronDown';
import ChevronUp from '../icons/ChevronUp';

const faqHeaderStyle = {
  padding: '1rem',
  margin: '0 0 -1px',
  background: colors.red,
  color: colors.white,
  cursor: 'pointer',
};

const faqBodyStyle = {
  padding: '1rem 1rem 0',
  border: `1px solid ${colors.red}`,
};

const FAQ = ({ question, answer }) => {
  const [expanded, toggleExpanded] = React.useState(false);

  const handleExpanded = () => {
    toggleExpanded(!expanded);
  };

  return (
    <div onClick={handleExpanded} style={{ padding: 0, margin: '0 0 1rem' }}>
      <FlexWrapper align='center' justify='between' style={faqHeaderStyle}>
        <h3
          style={{
            margin: 0,
            fontSize: '0.875rem',
            fontWeight: 300,
            lineHeight: '1.4',
          }}
        >
          {question}
        </h3>
        {expanded ? (
          <ChevronUp aspect='1.25rem' color={colors.white} />
        ) : (
          <ChevronDown aspect='1.25rem' color={colors.white} />
        )}
      </FlexWrapper>
      {expanded && <div style={faqBodyStyle}>{answer}</div>}
    </div>
  );
};

export default FAQ;
