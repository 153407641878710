import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NewYorkCityScreengrab = () => (
  <StaticImage
    src='../../../images/samples/run-1/new-york-city-mondrian-sample.jpg'
    alt={`Isolated sample of Mondrian's New York City I`}
    placeholder='dominantColor'
  />
);

export default NewYorkCityScreengrab;
