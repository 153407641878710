import * as React from 'react';

import colors from '../colors/colors';
import bespokeBoogieWoogieData from '../data/bespokeBoogieWoogie';

const BespokeBoogieWoogie = ({ anchor }) => (
  <div
    style={{
      margin: 'calc(2rem + 2vw) auto',
      padding: '0 2rem',
      maxWidth: '40rem',
      padding: '0 1rem',
    }}
  >
    <h3
      id={anchor}
      className='Anchor'
      style={{
        fontSize: '2rem',
        color: colors.red,
        fontWeight: 300,
        margin: '0 0 1rem',
      }}
    >
      Bespoke Boogie-Woogie
    </h3>
    {bespokeBoogieWoogieData}
  </div>
);

export default BespokeBoogieWoogie;
