import * as React from 'react';

import SunflowersImage from '../../components/imageWrappers/runOne/Sunflowers';
import SunflowersScreengrab from '../../components/imageWrappers/samples/Sunflowers';

const sunflowersDetails = {
  seriesName: 'Run 1 Van Gogh Series',
  name: 'Sunflowers',
  artistName: 'Vincent van Gogh',
  artistBio: '1853 - 1890',
  date: 1889,
  medium: 'Oil on Canvas',
  measurements: '95 cm x 73 cm',
  classification: 'Painting',
  creditLine: `Van Gogh Museum, Amsterdam (Vincent van Gogh Foundation)`,
  // rights: `TBD`,
  link: `https://www.vangoghmuseum.nl/en/collection/s0031V1962`,
  location: `Van Gogh Museum`,
};

export default {
  Image: <SunflowersImage />,
  ReferenceImage: <SunflowersScreengrab />,
  details: sunflowersDetails,
  seriesPage: '/series/run-1-van-gogh',
};
