import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const SunflowersScreengrab = () => (
  <StaticImage
    src='../../../images/samples/run-1/sunflowers-van-gogh-sample.jpg'
    alt={`Isolated sample of Van Gogh's Sunflowers`}
    placeholder='dominantColor'
  />
);

export default SunflowersScreengrab;
