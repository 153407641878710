import * as React from 'react';

import NewYorkCityImage from '../../components/imageWrappers/runOne/NewYorkCity';
import NewYorkCityScreengrab from '../../components/imageWrappers/samples/NewYorkCity';

const newYorkCityDetails = {
  seriesName: 'Run 1 Mondrian Series',
  name: 'New York City I',
  artistName: 'Piet Mondrian',
  artistBio: '(1872, Netherlands - 1944, United States)',
  date: 1942,
  medium: 'Oil on Canvas',
  measurements: '119.3 x 114.2 cm',
  classification: 'Painting',
  creditLine: `Public Domain`,
  // rights: `TBD`,
  link: 'https://www.centrepompidou.fr/en/ressources/oeuvre/c5pRBL',
  location: 'Centre Pompidou',
};

export default {
  Image: <NewYorkCityImage />,
  ReferenceImage: <NewYorkCityScreengrab />,
  details: newYorkCityDetails,
  seriesPage: '/series/run-1-mondrian',
};
