import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NuBleuIIScreengrab = () => (
  <StaticImage
    src='../../../images/samples/run-1/nu-bleu-ii-matisse-sample.jpg'
    alt={`Isolated sample of Matisse's Nu Bleu II`}
    placeholder='dominantColor'
  />
);

export default NuBleuIIScreengrab;
