import * as React from 'react';
import links from './links';

export default (
  <div>
    <p>Do you have a piece of art that you love?</p>
    <p>
      We can Bespoke Boogie-Woogie your selected art or image by running it through our proprietary Boogie-Woogie
      process to deliver the perfectly customized NFT for you.
    </p>
    <p>
      How does it work? You email us at contact (at) boogie-woogie.io or reach out to us in{' '}
      <a href={links.discord}>Discord</a> with your included art/picture. Once we confirm availability, you can send
      payment to dandylines.eth and respond with your confirmation hash. Once we receive confirmation we will respond
      with confirmed Bespoke Boogie-Woogie ID # (1-345) and we will get started with work.
    </p>
    <p>
      We will mint the 1-of-1 NFT and transfer Bespoke Boogie-Woogie NFT to your payment address after review and
      approval. There are a maximum of 345 Bespoke Boogie-Woogie NFTs available to be created.
    </p>
    <p>As of November 5, 2021</p>
    <ul>
      <li>Current Bespoke Price: 0.5 ETH</li>
      <li>Current # of Minted Bespoke NFTs: 1/345</li>
    </ul>
  </div>
);
