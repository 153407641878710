import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const MuralForTheTerracePlazaHotel = () => (
  <StaticImage
    src='../../../images/framedDisplay/runOne/mural-for-the-terrace-plaza-hotel-miro-web-sample-run-1.png'
    alt='Dandy Lines Boogie-Woogie: Joan Miro, Mural for the Terrace Plaza Hotel, Cincinnati'
  />
);

export default MuralForTheTerracePlazaHotel;
