import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NuBleuII = () => (
  <StaticImage
    src='../../../images/framedDisplay/runOne/nu-bleu-ii-matisse-web-sample-run-1.png'
    alt='Dandy Lines Boogie-Woogie: Henri Matisse, Nu Bleu II'
  />
);

export default NuBleuII;
